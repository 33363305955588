<template>
  <page-loader v-if="loading"/>
  <v-row v-else>
    <v-col xl="8" lg="8" md="6" sm="12">
      <v-card class="mb-10">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          DB Schema Migration: {{ migration.name }}
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>
              <v-list-item-subtitle>{{ statusLabel }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>Created By </v-list-item-title>
              <v-list-item-subtitle>{{ createdByLabel }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text>
          <h4>Up Migration</h4>
          <prism-editor class="shepCodeEditor" v-model="migration.up" :highlight="highlighter"
                        line-numbers readonly/>
          <template v-if="project.requireDownMigration">
          <h4 class="mt-4">Down Migration</h4>
            <prism-editor class="shepCodeEditor" v-model="migration.down" :highlight="highlighter"
                          line-numbers readonly/>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="$router.push({
                name: 'db-schema-project-detail',
                params: { projectID }
              })"
          >
            Back to Project Detail
          </v-btn>
          <v-spacer/>
          <v-btn
            v-if="allowEdit"
            :disabled="loadingDummyRun"
            :loading="loadingDummyRun"
            @click="$router.push({
                name: 'db-schema-project-migration-upsert',
                params: { projectID, migrationID }
              })"
          >
            Edit Migration
          </v-btn>
          <v-btn
            v-if="allowDummyRun"
            color="green"
            :dark="!loadingDummyRun"
            :disabled="loadingDummyRun"
            :loading="loadingDummyRun"
            @click="deployDummy"
          >
            Start Dummy Run
          </v-btn>
          <v-btn
            v-if="migrationDeploymentComplete"
            color="green"
            dark
            @click="markComplete"
          >
            Complete Migration
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="statusDummyError" :loading="!dummyDeploymentResult">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          Dummy Run Error
        </v-card-title>
        <v-card-text v-if="dummyDeploymentResult" style="white-space: pre-line;">
          {{ dummyDeploymentResult.error.trim() }}
        </v-card-text>
      </v-card>
      <v-card v-if="statusError" :loading="!errorDeploymentResult">
        <v-card-title
          class="red darken-3 white--text"
        >
          Critical Run Error
        </v-card-title>
        <v-card-text v-if="errorDeploymentResult" style="white-space: pre-line;">
          {{ errorDeploymentResultError }}
        </v-card-text>
        <v-card-actions v-if="errorDeploymentResult">
          <v-spacer/>
          <v-dialog
            v-model="resetAndRerunDialog"
            max-width="650"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
                @click="lockMigration"
              >
                Reset & Rerun
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to reset and rerun this migration?
              </v-card-title>
              <v-card-text>
                <v-alert type="warning">
                  If you have not fixed the error <b>AND</b> corrected for any corruption this could
                  cause even worse issues with the database!
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="green darken-1"
                  text
                  @click="resetAndRerunDialog = false"
                >
                  No, Cancel
                </v-btn>
                <v-spacer/>
                <v-btn
                  color="orange darken-1"
                  :disabled="resetAndRerunLoading"
                  :loading="resetAndRerunLoading"
                  text
                  @click="resetAndRerun"
                >
                  Yes, I Accept the Consequences
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
      <v-card v-if="statusDummyComplete" :loading="!dummyDeploymentResult">
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          Migration Effects
        </v-card-title>
        <v-list v-if="dummyDeploymentResult" dense>
          <v-list-item
            v-for="item of Object.entries(dummyDeploymentResult.diff.table)"
            :key="`table-${item[0]}`"
          >
            <v-list-item-icon>
              <v-icon class="ml-5">fad fa-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Table: {{ item[0] }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="item[1].type === 'added'">
                  Created with schema:
                  <prism-editor class="shepCodeEditor" v-model="item[1].newSchema"
                                :highlight="highlighter"
                                line-numbers readonly/>
                </div>
                <div v-else-if="item[1].type === 'removed'">
                  Removed old schema:
                  <prism-editor class="shepCodeEditor" v-model="item[1].oldSchema"
                                :highlight="highlighter"
                                line-numbers readonly/>
                </div>
                <div v-else>
                  Old Schema:
                  <prism-editor class="shepCodeEditor" v-model="item[1].oldSchema"
                                :highlight="highlighter"
                                line-numbers readonly/>
                  New Schema:
                  <prism-editor class="shepCodeEditor" v-model="item[1].newSchema"
                                :highlight="highlighter"
                                line-numbers readonly/>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item of Object.entries(dummyDeploymentResult.diff.view)"
            :key="`view-${item[0]}`"
          >
            <v-list-item-icon>
              <v-icon class="ml-5">fad fa-window-frame-open</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                View: {{ item[0] }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Old Schema:
                <prism-editor class="shepCodeEditor" v-model="item[1].oldSchema"
                              :highlight="highlighter"
                              line-numbers readonly/>
                New Schema:
                <prism-editor class="shepCodeEditor" v-model="item[1].newSchema"
                              :highlight="highlighter"
                              line-numbers readonly/>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions v-if="dummyDeploymentResult">
          <v-spacer/>
          <v-btn
            color="green"
            dark
            @click="lockMigration"
          >
            Lock In Migration
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col xl="4" lg="4" md="6" sm="12">
      <v-card>
        <v-card-title
          class="blue-grey lighten-3 white--text"
        >
          <span class="text-h6">Deployment Progress</span>
        </v-card-title>
        <v-card-text class="py-0">
          <v-timeline dense>
            <v-timeline-item
              v-for="deployment of deploymentSortedList"
              :key="deployment.id"
              :color="deployment.timelineColor"
              small
            >
              {{ deployment.name }} -
              {{ deploymentStatus(deployment.id, true) }}
              <v-btn
                v-if="deploymentReady(deployment.id)"
                color="green"
                :dark="!loadingRun"
                :disabled="loadingRun"
                :loading="loadingRun"
                @click="deploy(deployment)"
              >
                Run Migration
              </v-btn>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {db} from "@/firestore";
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-tomorrow.css";
import dbSchema from "@/data/dbSchema";
import PageLoader from "@/components/Page/Loader";
import triggerDeployment from "@/trigger/dbSchema/Deployment";

export default {
  name: "PageDbSchemaProjectMigrationDetail",
  components: {
    PageLoader,
    PrismEditor,
  },
  data() {
    return {
      deploymentList: null,
      loadingDummyRun: false,
      loadingRun: false,
      migration: null,
      migrationDeploymentList: null,
      project: null,
      resetAndRerunDialog: false,
      resetAndRerunLoading: false,
    };
  },
  computed: {
    allowDummyRun() {
      return this.status ? this.status.allowDummyRun : false;
    },
    allowEdit() {
      return this.status ? this.status.allowEdit : false;
    },
    createdByLabel() {
      if (!this.migration.status) {
        return "Loading...";
      }
      return this.migration?.displayName ? this.migration?.displayName : `${this.migration?.createdBy}`;
    },
    colorDev() {
      return "light-green";
    },
    colorDummy() {
      return "light-blue";
    },
    colorPreProduction() {
      return "purple";
    },
    colorProduction() {
      return "deep-orange";
    },
    deploymentSortedList() {
      return [
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.DUMMY.value).
          map(d => ({...d, id: d.id, timelineColor: this.colorDummy})),
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.DEV.value).
          sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          }).
          map(d => ({...d, id: d.id, timelineColor: this.colorDev})),
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.PREPRODUCTION.value).
          sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          }).
          map(d => ({...d, id: d.id, timelineColor: this.colorPreProduction})),
        ...this.deploymentList.filter(d => d.type === dbSchema.DEPLOYMENT_TYPE.PRODUCTION.value).
          map(d => ({...d, id: d.id, timelineColor: this.colorProduction})),
      ];
    },
    dummyDeploymentResult() {
      return this.migrationDeploymentList.find(d => d.id ===
        this.deploymentList.find(d => d.type === dbSchema.DEPLOYMENT_TYPE.DUMMY.value).id);
    },
    errorDeploymentResult() {
      return this.migrationDeploymentList.find(d => ![
        dbSchema.MIGRATION_STATUS.COMPLETE.value,
        dbSchema.MIGRATION_STATUS.DUMMY_COMPLETE.value].includes(d.status));
    },
    errorDeploymentResultError() {
      if (!this.errorDeploymentResult) {
        return "Loading...";
      }
      return this.errorDeploymentResult.error ? this.errorDeploymentResult.error.trim() : "Unknown";
    },
    loading() {
      return this.deploymentList === null || this.migration === null ||
        this.migrationDeploymentList === null || this.project === null;
    },
    migrationDeploymentComplete() {
      if (this.migration.status !== dbSchema.MIGRATION_STATUS.LOCKED.value) {
        return false;
      }
      if (!this.migrationDeploymentList || !this.deploymentList ||
        this.migrationDeploymentList.length !== this.deploymentList.length) {
        return false;
      }
      return !this.migrationDeploymentList.find(
        d => d.status !== dbSchema.MIGRATION_DEPLOYMENT_STATUS.COMPLETE.value);
    },
    migrationID() {
      return this.$route.params.migrationID;
    },
    projectID() {
      return this.$route.params.projectID;
    },
    status() {
      return Object.values(dbSchema.MIGRATION_STATUS).find(s => s.value === this.migration.status);
    },
    statusDummyComplete() {
      if (!this.migration) return false;
      return this.migration.status === dbSchema.MIGRATION_STATUS.DUMMY_COMPLETE.value;
    },
    statusDummyError() {
      if (!this.migration) return false;
      return this.migration.status === dbSchema.MIGRATION_STATUS.DUMMY_ERROR.value;
    },
    statusError() {
      if (!this.migration) return false;
      return this.migration.status === dbSchema.MIGRATION_STATUS.ERROR.value;
    },
    statusLabel() {
      if (!this.migration.status) {
        return "Loading...";
      }
      return this.status ? this.status.label : `Unknown: ${this.migration.status}`;
    },
  },
  watch: {
    migration(newValue, oldValue) {
      if (newValue && newValue.status && oldValue && oldValue.status) {
        this.loadingDummyRun = false;
      }
    },
    migrationDeploymentList() {
      this.loadingRun = false;
    },
  },
  methods: {
    deploymentReady(deploymentID) {
      if (this.migration.status !== dbSchema.MIGRATION_STATUS.LOCKED.value) {
        return false;
      }
      const blockingDeployment = this.migrationDeploymentList.filter(
        d => d.status !== dbSchema.MIGRATION_DEPLOYMENT_STATUS.COMPLETE.value);
      if (blockingDeployment.length) {
        return false;
      }
      const nextDeployment = this.deploymentSortedList.find(
        d => this.deploymentStatus(d.id) === "not-run");
      return nextDeployment && nextDeployment.id === deploymentID;
    },
    deploymentStatus(deploymentID, friendly = false) {
      const status = this.migrationDeployment(deploymentID)
        ? this.migrationDeployment(deploymentID).status
        : "not-run";
      if (!friendly) {
        return status;
      }
      const mgStatus = Object.values(dbSchema.MIGRATION_DEPLOYMENT_STATUS).
        find(s => s.value === status);
      return mgStatus ? mgStatus.label : `Unknown (${status})`;
    },
    deploy(deployment) {
      if (!this.deploymentStatus(deployment.id)) {
        return false;
      }
      this.loadingRun = true;
      this.triggerDeployment(deployment.id);
    },
    deployDummy() {
      if (!this.allowDummyRun) return false;
      const dummyDeployment = this.deploymentList.find(
        d => d.type === dbSchema.DEPLOYMENT_TYPE.DUMMY.value);
      if (!dummyDeployment) return false;
      this.loadingDummyRun = true;
      this.triggerDeployment(dummyDeployment.id);
    },
    highlighter(code) {
      return highlight(code, languages.sql);
    },
    lockMigration() {
      if (!this.statusDummyComplete) {
        return false;
      }
      db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID).
        update({
          status: dbSchema.MIGRATION_STATUS.LOCKED.value,
          affectedTableList: Object.keys(this.dummyDeploymentResult.diff.table).sort(),
          createdTableList: Object.entries(this.dummyDeploymentResult.diff.table).filter(t => t[1].type === "added").map(t => t[0]).sort(),
        });
    },
    markComplete() {
      if (!this.migrationDeploymentComplete) {
        return false;
      }
      db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID).
        update({status: dbSchema.MIGRATION_STATUS.COMPLETE.value});
    },
    migrationDeployment(deploymentID) {
      return this.migrationDeploymentList.find(d => d.id === deploymentID);
    },
    resetAndRerun() {
      if (!this.errorDeploymentResult) {
        return false;
      }
      this.resetAndRerunLoading = true;
      const deploymentID        = this.errorDeploymentResult.id;
      db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID).
        collection("deployment").
        doc(deploymentID).delete().then(() => {
        db.collection("db-schema-project").
          doc(this.projectID).
          collection("migration").
          doc(this.migrationID).
          update({
            status: dbSchema.MIGRATION_STATUS.LOCKED.value,
          }).
          then(() => {
            this.loadingRun = true;
            this.triggerDeployment(deploymentID);
            this.resetAndRerunLoading = false;
            this.resetAndRerunDialog = false;
          });
      });
    },
    triggerDeployment(deploymentID) {
      new triggerDeployment(this.projectID, this.migrationID, deploymentID).trigger();
    },
  },
  firestore() {
    return {
      deploymentList: db.collection("db-schema-project").
        doc(this.projectID).
        collection("deployment"),
      migration: db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID),
      migrationDeploymentList: db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID).
        collection("deployment"),
      project: db.collection("db-schema-project").
        doc(this.projectID),
    };
  },
};
</script>

<style scoped>

</style>
]#]
