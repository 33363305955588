export default {
  MIGRATION_DEPLOYMENT_STATUS: {
    BACKUP_COMPLETE: {
      label: "Backup complete",
      value: "backup-complete",
    },
    BACKUP_FAILED: {
      label: "Backup failed",
      value: "backup-failed",
    },
    BACKUP_IN_PROGRESS: {
      label: "Backup in progress",
      value: "backup-in-progress",
    },
    COMPLETE: {
      label: "Complete",
      value: "complete",
    },
    INITIALISING: {
      label: "Initialising",
      value: "initialising",
    },
    MIGRATED: {
      label: "Migrated",
      value: "migrated",
    },
    NOT_RUN: {
      label: "Not run yet",
      value: "not-run",
    },
    READY: {
      label: "Ready to migrate",
      value: "ready",
    },
    STARTING: {
      label: "Starting",
      value: "starting",
    },
    ERROR_MIGRATION_BASE: {
      label: "Error with previous migration schema",
      value: "error-migration-base",
    },
    ERROR_MIGRATION_DOWN: {
      label: "Error with migration down schema",
      value: "error-migration-down",
    },
    ERROR_MIGRATION_UP: {
      label: "Error with migration up schema",
      value: "error-migration-up",
    },
    ERROR_ROLLBACK_MISMATCH: {
      label: "Mismatch with down schema",
      value: "error-rollback-mismatch",
    },
  },
  MIGRATION_STATUS: {
    COMPLETE: {
      allowDummyRun: false,
      allowEdit: false,
      allowRun: false,
      label: "Complete",
      value: "complete",
    },
    DUMMY_COMPLETE: {
      allowDummyRun: false,
      allowEdit: true,
      allowRun: false,
      label: "Dummy run complete",
      value: "dummy-complete",
    },
    DUMMY_ERROR: {
      allowDummyRun: false,
      allowEdit: true,
      allowRun: false,
      label: "Dummy migration failed",
      value: "dummy-error",
    },
    DUMMY_IN_PROGRESS: {
      allowDummyRun: false,
      allowEdit: false,
      allowRun: false,
      label: "Dummy run in progress",
      value: "dummy-in-progress",
    },
    ERROR: {
      allowDummyRun: false,
      allowEdit: false,
      allowRun: false,
      label: "Critical migration failure",
      value: "error",
    },
    LOCKED: {
      allowDummyRun: false,
      allowEdit: false,
      allowRun: true,
      label: "Schema Locked",
      value: "locked",
    },
    NEW: {
      allowDummyRun: true,
      allowEdit: true,
      allowRun: false,
      label: "New migration",
      value: "new",
    },
  },
  DEPLOYMENT_TYPE: {
    DUMMY: {
      label: "Dummy Run",
      value: "dummy",
    },
    DEV: {
      label: "Developer Database",
      value: "dev",
    },
    PREPRODUCTION: {
      label: "Pre-production Database",
      value: "preproduction",
    },
    PRODUCTION: {
      label: "Production Database",
      value: "production",
    },
  }
};
