<template>
  <v-card :disabled="mode === 'loading'">
    <v-card-title v-if="mode === 'loading'">
      Loading...
    </v-card-title>
    <v-card-title v-if="mode === 'create'">
      Create DB Schema Project
    </v-card-title>
    <v-card-title v-if="mode === 'edit'">
      Edit DB Schema Project
    </v-card-title>

    <v-card-text>
      <v-form ref="projectForm">
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
        />
        <v-switch
          v-model="requireDownMigration"
          label="Require Down Migration"
        ></v-switch>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="mr-4"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="submitForm"
      >
        {{ mode === 'create' ? 'Create Project' : 'Edit Project' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {db} from "@/firestore";
import _ from "lodash";

export default {
  name: "PageDbSchemaProjectUpsert",
  data() {
    return {
      mode: 'loading',
      name: "",
      nameTaken: true,
      nameRules: [
        v => !!v || 'Please enter a project name',
        v => this.nameTaken === true || this.nameTaken,
      ],
      requireDownMigration: false,
    };
  },
  computed: {
    projectData() {
      return {
        name: this.name.trim(),
        requireDownMigration: this.requireDownMigration,
      };
    },
    projectID() {
      return this.$route.params.projectID;
    },
  },
  mounted() {
    if (this.projectID) {
      db.collection('db-schema-project').doc(this.projectID).get().then((project) => {
        if (!project.exists) {
          this.$notify({
            type: 'error',
            title: 'Missing Project',
            text: `Cannot find a project in the system under the ID: ${this.projectID}`,
          });
          this.$router.push({ name: 'db-schema-project-list' });
        } else {
          for (const key of Object.keys(project.data())) {
            if (["name", "requireDownMigration"].includes(key)) {
              this[key] = project.data()[key];
            }
          }
          this.mode = 'edit';
        }
      });
    } else {
      this.mode = 'create';
    }
  },
  methods: {
    cancel() {
      switch (this.mode) {
        case 'edit':
          this.$router.push({
            name: 'db-schema-project-detail',
            params: { projectID: this.projectID },
          });
          break;
        case 'create':
        default:
          this.$router.push({
            name: 'db-schema-project-list',
          });
          break;
      }
    },
    submitForm() {
      if (this.$refs.projectForm.validate()) {
        switch (this.mode) {
          case 'create':
            db.collection('db-schema-project')
            .add(this.projectData)
            .then((document) => {
              this.$router.push({
                name: 'db-schema-project-detail',
                params: { projectID: document.id },
              });
            });
            break;

          case 'edit':
            db.collection('db-schema-project')
            .doc(this.projectID)
            .update(this.projectData)
            .then(() => {
              this.$router.push({
                name: 'db-schema-project-detail',
                params: { projectID: this.projectID },
              });
            });
            break;
          default:
            this.$notify({
              type: 'error',
              title: 'Unknown Mode',
              text: `Cannot action unknown mode: ${this.mode}`,
            });
            break;
        }
      } else {
        this.$notify({
          type: 'warning',
          title: 'Validation Failed',
          text: `Please correct the errors with your form`,
        });
      }
    },
  },
  watch: {
    name: _.debounce(function(value){
      const firestore = db;
      this.nameTaken  = true;
      this.$refs.projectForm.validate();
      if (value !== "") {
        firestore.collection('db-schema-project')
        .where('name', '==', value.trim())
        .get()
        .then((existing) => {
          if (existing.size > 0 && existing.docs[0].id !== this.projectID) {
            this.nameTaken = 'A project with this name already exists';
            this.$refs.projectForm.validate();
          }
        });
      }
    }, 250)
  },
};
</script>

<style scoped>

</style>
