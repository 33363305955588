<template>
  <v-card :disabled="mode === 'loading' || migrationList === null || project === null">
    <v-card-title v-if="mode === 'loading'">
      Loading...
    </v-card-title>
    <v-card-title v-if="mode === 'create'">
      Create DB Schema Migration
    </v-card-title>
    <v-card-title v-if="mode === 'edit'">
      Edit DB Schema Migration
    </v-card-title>
    <v-card-text>
      <v-alert v-if="createMigrationBlocked" type="warning">
        Only one migration can be active at a time to protect against conflicts.
      </v-alert>
      <v-form
        v-else-if="mode !== 'loading'"
        ref="migrationForm"
      >
        <v-text-field
          v-model="name"
          label="Name"
          :rules="nameRules"
        />
        <h4>Up Migration</h4>
        <prism-editor class="shepCodeEditor" v-model="up" :highlight="highlighter" line-numbers/>
        <template v-if="project && project.requireDownMigration">
          <h4 class="mt-4">Down Migration</h4>
          <prism-editor class="shepCodeEditor" v-model="down" :highlight="highlighter" line-numbers/>
        </template>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="mr-4"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-spacer/>
      <v-btn
        v-if="!createMigrationBlocked"
        color="primary"
        @click="submitForm"
      >
        {{ mode === "create" ? "Create Migration" : "Edit Migration" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import {auth, db} from "@/firestore";
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-tomorrow.css";
import dbSchema from "@/data/dbSchema";

export default {
  name: "PageDbSchemaProjectMigrationUpsert",
  components: {
    PrismEditor,
  },
  data() {
    return {
      down: "",
      migrationList: null,
      mode: "loading",
      name: "",
      nameTaken: true,
      nameRules: [
        v => !!v || "Please enter a migration name",
        v => this.nameTaken === true || this.nameTaken,
      ],
      project: null,
      up: "",
    };
  },
  computed: {
    createMigrationBlocked() {
      return this.mode === "create" && this.migrationList !== null &&
        !!this.migrationList.filter(m => m.status !== dbSchema.MIGRATION_STATUS.COMPLETE.value).length;
    },
    migrationData() {
      const data = {
        down: this.down.trim(),
        name: this.name.trim(),
        up: this.up.trim(),
        status: dbSchema.MIGRATION_STATUS.NEW.value,
      };
      if (this.mode === "create") {
        data.createdBy = auth.currentUser.uid;
        data.displayName = auth.currentUser.displayName;
      }
      return data;
    },
    migrationID() {
      return this.$route.params.migrationID;
    },
    projectID() {
      return this.$route.params.projectID;
    },
  },
  mounted() {
    if (this.migrationID) {
      db.collection("db-schema-project").
        doc(this.projectID).
        collection("migration").
        doc(this.migrationID).
        get().
        then((migration) => {
          if (!migration.exists) {
            this.$notify({
              type: "error",
              title: "Missing Migration",
              text: `Cannot find a migration in the system under the ID: ${this.projectID}/${this.migrationID}`,
            });
            return this.$router.push({name: "db-schema-project-detail", params: {projectID: this.projectID}});
          }
          const status = Object.values(dbSchema.MIGRATION_STATUS).find(s => s.value === migration.data().status);
          if (!status || !status.allowEdit) {
            this.$notify({
              type: "error",
              title: "Migration Not Editable",
              text: `Migration is not allowed to be edited due to it's status (${migration.data().status}): ${this.projectID}/${this.migrationID}`,
            });
            return this.$router.push({
              name: "db-schema-project-migration-detail",
              params: {projectID: this.projectID, migrationID: document.id},
            });
          }
          for (const key of Object.keys(migration.data())) {
            if (["down", "name", "up"].includes(key)) {
              this[key] = migration.data()[key];
            }
          }
          this.mode = "edit";
        });
    } else {
      this.mode = "create";
    }
  },
  methods: {
    cancel() {
      switch (this.mode) {
        case "edit":
          this.$router.push({
            name: "db-schema-project-migration-detail",
            params: {projectID: this.projectID, migrationID: this.migrationID},
          });
          break;
        case "create":
        default:
          this.$router.push({name: "db-schema-project-detail", params: {projectID: this.projectID}});
          break;
      }
    },
    highlighter(code) {
      return highlight(code, languages.sql);
    },
    submitForm() {
      if (this.$refs.migrationForm.validate()) {
        if (!this.up.trim().length) {
          this.$notify({
            type: "warning",
            title: "Up Migration Missing",
            text: `Please enter an up migration`,
          });
        } else if (!this.down.trim().length && this.project.requireDownMigration) {
          this.$notify({
            type: "warning",
            title: "Down Migration Missing",
            text: `Please enter a down migration`,
          });
        } else {
          switch (this.mode) {
            case "create":
              db.collection("db-schema-project").
                doc(this.projectID).
                collection("migration").
                add(this.migrationData).
                then((document) => {
                  this.$router.push({
                    name: "db-schema-project-migration-detail",
                    params: {projectID: this.projectID, migrationID: document.id},
                  });
                });
              break;

            case "edit":
              db.collection("db-schema-project").
                doc(this.projectID).
                collection("deployment").
                get().then(deployments => {
                  const dummyDeployment = [];
                  for (const deployment of deployments.docs) {
                    if (deployment.data().type === 'dummy') {
                      dummyDeployment.push(deployment.id);
                    }
                  }
                  db.collection("db-schema-project").
                    doc(this.projectID).
                    collection("migration").
                    doc(this.migrationID).
                    collection("deployment").
                    get().then(async (deployments) => {
                      for (const deployment of deployments.docs) {
                        if(dummyDeployment.includes(deployment.id)) {
                          await db.collection("db-schema-project").
                            doc(this.projectID).
                            collection("migration").
                            doc(this.migrationID).
                            collection("deployment").
                            doc(deployment.id).
                            delete();
                        }
                      }
                      db.collection("db-schema-project").
                        doc(this.projectID).
                        collection("migration").
                        doc(this.migrationID).
                        update(this.migrationData).
                        then(() => {
                          this.$router.push({
                            name: "db-schema-project-migration-detail",
                            params: {projectID: this.projectID, migrationID: this.migrationID},
                          });
                        });
                  })
              })
              break;
            default:
              this.$notify({
                type: "error",
                title: "Unknown Mode",
                text: `Cannot action unknown mode: ${this.mode}`,
              });
              break;
          }
        }
      } else {
        this.$notify({
          type: "warning",
          title: "Validation Failed",
          text: `Please correct the errors with your form`,
        });
      }
    },
  },
  watch: {
    name: _.debounce(function(value){
      const firestore = db;
      this.nameTaken  = true;
      this.$refs.migrationForm.validate();
      if (value !== "") {
        firestore.collection("db-schema-project").
          doc(this.projectID).
          collection("migration").
          where("name", "==", value.trim()).
          get().
          then((existing) => {
            if (existing.size > 0 && existing.docs[0].id !== this.migrationID) {
              this.nameTaken = "A migration with this name already exists";
              this.$refs.migrationForm.validate();
            }
          });
      }
    }, 250)
  },
  firestore() {
    return {
      migrationList: db.collection("db-schema-project").doc(this.projectID).collection("migration"),
      project: db.collection("db-schema-project").doc(this.projectID),
    };
  },
};
</script>

<style scoped>
</style>
